<script lang="ts">
import Button from '@igefa-e-commerce/pattern-library/src/components/Button/Button.svelte'
import Text from '@igefa-e-commerce/pattern-library/src/components/Text/Text.svelte'
import { onMount } from 'svelte'
import type { PromotionCarouselItem, PromotionCarouselKey } from './index.ts'

export let linkToOrder: string | undefined = undefined
export let showCarousel = false
export let showButton = false
export let carouselItems: PromotionCarouselKey[]

const promotionCarouselItems: Record<
  PromotionCarouselKey,
  PromotionCarouselItem
> = {
  reorder: {
    text: '<strong>Bestellen Sie</strong> einzelne oder mehrere <strong>Produkte ganz einfach erneut</strong>, ohne lange zu suchen.',
    alt: 'Bestellen Sie einzelne oder mehrere Produkte ganz einfach erneut, ohne lange zu suchen.',
    href: '/de/user/my-account/orders/articles',
    label: 'Erneut bestellen',
    svg: `https://${
      import.meta.env.PUBLIC_DOMAIN_NAME
    }/carousel-feature-reorder.svg`,
  },
  tracking: {
    text: 'Status-Informationen und <strong>vollständiges Tracking</strong> bei allen Bestellungen.',
    alt: 'Status-Informationen und vollständiges Tracking bei allen Bestellungen.',
    href: linkToOrder,
    label: 'Tracking',
    svg: `https://${
      import.meta.env.PUBLIC_DOMAIN_NAME
    }/carousel-feature-tracking.svg`,
  },
  orders: {
    text: 'Übersichtliche <strong>Lieferhistorie, Rechnungen</strong> und alle Bestellungen.',
    alt: 'Übersichtliche Lieferhistorie, Rechnungen und alle Bestellungen.',
    href: '/de/user/my-account',
    label: 'Zu den Bestellungen',
    svg: `https://${
      import.meta.env.PUBLIC_DOMAIN_NAME
    }/carousel-feature-orders.svg`,
  },
}

let current = 0
let intervalHandler: number

function runCarousel() {
  return setInterval(() => {
    current = (current + 1) % carouselItems.length
  }, 4000)
}

onMount(() => {
  if (showCarousel) {
    intervalHandler = runCarousel()
  }
})

function onClickIndicator(index: number) {
  if (intervalHandler) {
    clearInterval(intervalHandler)
  }

  current = index

  intervalHandler = runCarousel()
}
</script>

<div class='container'>
  {#if showCarousel}
    <div class='carousel' style={`--current: ${current}`}>
      <div class='items'>
        {#each carouselItems as key}
          {@const item = promotionCarouselItems[key]}
          <div class='carousel-item'>
            <img src={item.svg} alt={item.alt} />
            <Text>{@html item.text}</Text>

            {#if showButton && item.href}
              <Button variant='tertiary' on:click={() => window.location.href = item.href}>{item.label}</Button>
            {/if}
          </div>
        {/each}
      </div>
    </div>

    <div class='indicator'>
      {#each carouselItems as key}
        <i
          class='circle'
          class:active={key === carouselItems[current]}
          on:click={() => onClickIndicator(carouselItems.indexOf(key))}
        >
        </i>
      {/each}
    </div>
  {:else}
    {@const item = promotionCarouselItems[carouselItems[0]]}

    <div class='carousel-item'>
      <img src={item.svg} alt={item.alt} />
      <Text>{@html item.text}</Text>

      {#if showButton && item.href}
        <Button variant='tertiary' on:click={() => window.location.href = item.href}>{item.label}</Button>
      {/if}
    </div>
  {/if}
</div>

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/colors.scss';

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
  }

  .carousel {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
  }

  .items {
    display: flex;
    transition: transform 0.5s ease-in-out;
    transform: translateX(calc(var(--current) * 100% * (-1)));
  }

  .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    min-width: 100%;
    text-align: center;
  }

  .circle {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    border: 1px solid #000;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 0.5rem;
    transition: background-color 0.5s ease-in-out;

    &:hover {
      background-color: colors.$color_interaction_hover;
    }

    &.active {
      background-color: colors.$color_interaction_active;
    }
  }
</style>